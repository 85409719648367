import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate, useLocation } from "react-router-dom";
import QRCode from "qrcode.react";
import { checkPayment } from "../services/payment.service";
import { ToastContainer, toast } from "react-toastify";

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [text, setText] = useState("");

  useEffect(() => {
    if (location == null || location.state === null) {
      navigate("/");
    }

    const detectDeviceType = () => {
      setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
    };

    detectDeviceType();

    // Add event listener to detect changes in user agent string
    window.addEventListener("resize", detectDeviceType);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", detectDeviceType);
    };
  }, []);
  return (
    <div className="min-h-screen bg-[#0D0D0D]">
      <div className="flex justify-between items-center px-5 pt-10">
        {console.log("location = ", location)}
        <div></div>
      </div>
      <div className=" px-5 mt-10 flex justify-center">
        <div className=" border-t border-white flex items-center justify-between pt-5">
          <p className="text-white opacity-80 text-lg text-bold pr-10">
            Нийт дүн{" "}
          </p>
          {
            location.state.isPromo === true ? <p className="text-white opacity-80 text-xl">₮100</p>
              : <p className="text-white opacity-80 text-xl">₮55.000</p>
          }
        </div>
      </div>
      <div className="mt-5 px-5 flex justify-center flex-col items-center">
        <p className="text-white my-3 ms-1 opacity-80">
          {isMobile ? " Q pay ашиглан төлөх сонголтууд" : "Q pay"}

        </p>
        {isMobile ? (
          <div className="flex justify-center">
            <div className="grid grid-cols-2 gap-4 max-w-[320px]">
              {location.state !==
                null &&
                location.state.qpay.urls.map((val, index) => (
                  <button
                    onClick={() => {
                      window.open(val.link, "_blank");
                    }}
                    key={index}
                    className="flex items-center gap-3 bg-[#222222] rounded-lg text-white p-2"
                  >
                    <img src={val.logo} alt="qpay" width={40} height={40} />
                    <p className="opacity-80 text-sm">{val.name}</p>
                  </button>
                ))}
            </div>
          </div>
        ) : (
          <div className="border border-white">
            <QRCode
              width={400}
              height={400}
              value={location.state.qpay.qr_text}
            />
          </div>
        )}

        <div>
          <button
            onClick={() => {
              checkPayment(location.state.qpay.invoice_id).then((val) => {
                if (val.data && val.data.status && val.data.status === "PAID") {
                  navigate("/payment/success");
                } else {
                  toast.error("Таны төлбөр төлөгдөөгүй байна.");
                }
              });
            }}
            className={`text-white mt-10 mb-10 bottom-10 bg-[#1f1f1f] w-[250px] px-6 py-2.5 rounded-2xl flex items-center gap-2 justify-center `}
          >
            Төлбөр шалгах
            {/* <FaArrowRight /> */}
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Payment;
