import React, { useState, useEffect, useRef } from "react";
import "../index.css"; // Import CSS file for animations
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "animate.css";

const Mainpage = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [animationTrigger, setAnimationTrigger] = useState(false);
  const carouselRef = useRef(null);
  const [promo, setPromo] = useState("")

  useEffect(() => {
    const timeout = setTimeout(() => {
      setStep((prevStep) => prevStep + 1);
    }, 3000);

    if (step === 3 || step === 4) {
      clearTimeout(timeout);
    }

    return () => clearTimeout(timeout);
  }, [step]);

  useEffect(() => {
    if (step === 1 || step === 2) {
      setAnimationTrigger(false);
      const timeout = setTimeout(() => {
        setAnimationTrigger(true);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [step]);

  return (
    <div className="min-h-screen bg-[#0D0D0D] px-10">
      <div className="absolute right-10 top-10">
        <img
          className="w-[80px] sm:w-[100px] md:w-[120px]"
          src="./logo.svg"
          alt="logo"
        />
      </div>
      <div className="flex min-h-screen justify-center  items-center ">
        <div className={`step-${step}`}>
          <div className="animate__animated">
            {step === 1 && (
              <div
                className={`animate__animated ${animationTrigger ? "animate__fadeOutUp" : ""
                  }`}
              >
                <div className="flex items-center mb-10">
                  <p className="text-lg text-white">Сайн байна уу</p>
                  <img src="./hand.png" alt="hand" className="waving-hand" />
                </div>
              </div>
            )}
            {step === 2 && (
              <div
                className={`animate__animated ${animationTrigger ? "animate__fadeOutDown" : ""
                  }`}
              >
                <p className="text-lg text-white">
                  Метаког-д тавтай морилно уу!
                </p>
              </div>
            )}
            {step === 3 && (
              <div className={`animate__animated animate__fadeIn pb-10 `}>
                <div className="flex justify-center mb-3">
                  <img src="./emoji.svg" alt="emoji" />
                </div>
                <p className="text-xl mb-3 font-semibold text-white">
                  Unlock Your Potential with Games
                </p>
                <br />
                <p className="text-base text-white w-[280px] ms-4 leading-[22.7px] text-start">
                  Шинжлэх ухаанд суурилсан,{" "}
                  <span className="text-[#ff9d72] font-black">
                    сонирхолтой тоглоомоор
                  </span>{" "}
                  дамжуулан зөөлөн ур чадваруудыг илрүүлэн, сайжруулахад тань
                  тусалъя.
                </p>
                <p className="text-base text-white mt-[10px] w-[280px] mb-12 ms-4 leading-[22.7px] ">
                  10.000 гаруй хүмүүсийн тоглоомын үр дүнтэй Та өөрийн ур
                  чадваруудыг харьцуулж, танин мэдэж, сайжруулахыг хүсвэл
                  Үргэлжлүүлэх дараарай
                </p>
                <div>
                  <label className="text-[#cfcfcf] ms-1 mb-1 block">
                    Promo code
                  </label>
                  <input
                    className="text-white bg-inherit px-4 py-2 border border-[#4f4f4f] w-full rounded-xl"
                    type="text"
                    placeholder=""
                    value={promo}
                    onChange={(e) =>
                      setPromo(e.target.value)
                    }
                  />
                </div>
              </div>
            )}

            {step === 4 && (
              <div className={`animate__animated animate__fadeIn pb-10 `}>
                <div className="flex justify-center mb-3">
                  <img src="./controller.svg" alt="emoji" />
                </div>
                <p className="text-xl mb-8 text-center font-semibold text-white">
                  Start Playing Now{" "}
                </p>
                <br />
                <p className="text-white">
                  {" "}
                  25 минут үргэлжлэх мини-тоглоомуудыг <br />
                  дуусгахад:
                </p>
                <br />
                <ul className="text-white list-disc w-[280px]">
                  <li className="ms-8">
                    Танд{" "}
                    <span className="text-[#ff9d72] font-black">
                      19 өөр ур чадваруудын
                    </span>{" "}
                    дэлгэрэнгүй тайлан илгээгдэнэ .
                  </li>
                  <br />
                  <li className="ms-8">
                    {" "}
                    Хиймэл оюун ухааны тусламжтайгаар,{" "}
                    <span className="text-[#ff9d72] font-black">
                      зөвхөн танд зориулагдсан {""}
                    </span>
                    зөвлөгөөнүүдийг илгээх болно.
                  </li>
                </ul>
                <br />
                <p className="text-white mt-4">
                  {(promo !== null && promo !== "" && promo.toUpperCase() === "TALENTSUMMIT") ?
                    "Тоглоомын төлбөр: ₮100" : "Тоглоомын төлбөр: ₮55.000"
                  }</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {step >= 3 ? (
        <div className="flex justify-center">
          <button
            onClick={() => {
              step === 4 ? navigate("/signup", {
                state: {
                  promo,
                },
              }) : setStep(4);
            }}
            className={`text-white absolute bottom-1  bg-[#1f1f1f] w-[310px] px-6 py-2.5 rounded-2xl flex items-center gap-2 justify-center`}
          >
            Үргэлжлүүлэх
            <FaArrowRight />
          </button>
        </div>
      ) : (
        <div className="absolute w-full flex justify-center  bottom-10 left-0">
          <div className="sm:w-[350px]">
            <div>
              <p className="text-white opacity-80 ms-5 mb-5">
                Хамтрагч байгууллагууд
              </p>
              <div className="carousel-container">
                <div ref={carouselRef} className="carousel">
                  <img
                    className="h-full"
                    src="./partner/microsoft.png"
                    alt="microsoft"
                  />
                  <img
                    className="h-full mt-2"
                    src="./partner/google.png"
                    alt="google"
                  />
                  <img
                    className="h-full mt-1"
                    src="./partner/mstars.png"
                    alt="mstars"
                  />
                  <img className="h-full" src="./partner/ucla.png" alt="ucla" />{" "}
                  <img
                    className="h-full"
                    src="./partner/microsoft.png"
                    alt="microsoft"
                  />{" "}
                  <img
                    className="h-full mt-2"
                    src="./partner/google.png"
                    alt="google"
                  />
                  <img
                    className="h-full mt-1"
                    src="./partner/mstars.png"
                    alt="mstars"
                  />
                  <img className="h-full" src="./partner/ucla.png" alt="ucla" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Mainpage;
