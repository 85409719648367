import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";

const Signup = () => {
  const location = useLocation();

  const [formData, setFormData] = useState({
    name: "",
    lastname: "",
    email: "",
    phoneNumber: null,
    isPromo: false
  });

  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    console.log("PROMO Location= ", location.state.promo)
    if (location.state.promo != null && location.state.promo.toUpperCase() === "METACOG2025") {
      setFormData((prevData) => ({
        ...prevData,
        isPromo: true,
      }));
    }
  }, [])

  useEffect(() => {
    const inputs = document.querySelectorAll(
      `#signup-container input:not([name="${step}"]), #signup-container label:not([for="${step}"])`
    );

    inputs.forEach((el) => {
      el.classList.add("fade-and-slide-out");
      el.addEventListener("animationend", () => {
        el.classList.remove("fade-and-slide-out");
      });
    });
  }, [step]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNextStep = () => {
    if (step === 3 && !emailRegex.test(formData.email)) {
      toast.error("И-мейл ээ зөв оруулна уу!");
      return;
    }

    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = () => {
    if (step === 4 && formData.phoneNumber.length !== 8) {
      toast.error("Утасны дугаар 8 оронтой байх ёстой");
      return;
    }
    try {
      if (
        formData.name === "" ||
        formData.lastname === "" ||
        formData.email === "" ||
        formData.phoneNumber === null
      ) {
        toast.error("Бүх талбарыг бөглөнө үү.");
        return;
      }
      // navigate("/resume");
      navigate("/resume", {
        state: {
          formData,
        },
      });
    } catch (err) {
      console.log(err);
      toast.error("Алдаа гарлаа");
    }
  };

  return (
    <div className="h-screen relative w-full bg-[#0D0D0D]">
      <div className="fixed top-10 right-10">
        <img
          className="w-[80px] sm:w-[100px] md:w-[120px]"
          src="./logo.svg"
          alt="logo"
        />
      </div>

      <div className="h-screen flex justify-center items-center">
        <div id="signup-container" className="w-full">
          <div className="pb-20 flex justify-center">
            <div>
              <label
                className="text-white text-base block ps-2 pb-2"
                htmlFor=""
              >
                {step === 1
                  ? "1. Нэр"
                  : step === 2
                    ? "2. Овог"
                    : step === 3
                      ? "3. Хариу илгээх И-мейл"
                      : step === 4
                        ? "4. Бүртгүүлэх утасны дугаар"
                        : ""}
              </label>
              <input
                onChange={handleInputChange}
                className="border-b px-2 w-[280px] h-[40px] text-lg border-white bg-inherit focus:border-b text-white "
                required
                placeholder={
                  step === 1
                    ? "Таны нэр"
                    : step === 2
                      ? "Таны овог"
                      : step === 3
                        ? "example@gmail.com"
                        : step === 4
                          ? "9999-9999"
                          : ""
                }
                type={step === 3 ? "email" : step === 4 ? "number" : "text"}
                name={
                  step === 1
                    ? "name"
                    : step === 2
                      ? "lastname"
                      : step === 3
                        ? "email"
                        : step === 4
                          ? "phoneNumber"
                          : ""
                }
                value={
                  step === 1
                    ? formData.name
                    : step === 2
                      ? formData.lastname
                      : step === 3
                        ? formData.email
                        : step === 4
                          ? formData.phoneNumber
                          : ""
                }
              />
            </div>
          </div>
        </div>
        {step === 1 && (
          <button
            onClick={handleNextStep}
            disabled={formData.name === ""}
            className={`text-white absolute bottom-10 bg-[#1f1f1f] w-[280px] px-6 py-2.5 rounded-2xl flex items-center gap-2 justify-center ${formData.name === "" ? "opacity-40" : "opacity-100"
              }`}
          >
            Үргэлжлүүлэх
            <FaArrowRight />
          </button>
        )}
        {step === 2 && (
          <>
            <div className="flex justify-center">
              <button
                onClick={handlePrevStep}
                className={`text-white absolute bottom-10 left-4 sm:left-[40%] bg-[#1f1f1f] w-[50px]  h-[44px] rounded-2xl flex items-center gap-2 justify-center`}
              >
                <IoChevronBack />
              </button>
              <button
                onClick={handleNextStep}
                disabled={formData.lastname === ""}
                className={`text-white absolute bottom-10 right-4 sm:right-[40%] bg-[#1f1f1f] w-[290px] sm:w-[235px] px-6 py-2.5 rounded-2xl flex items-center gap-2 justify-center ${formData.lastname === "" ? "opacity-40" : "opacity-100"
                  }`}
              >
                Үргэлжлүүлэх
                <FaArrowRight />
              </button>
            </div>
          </>
        )}
        {step === 3 && (
          <>
            <div className="flex justify-center">
              <button
                onClick={handlePrevStep}
                className={`text-white absolute bottom-10 left-4 sm:left-[40%] bg-[#1f1f1f] w-[50px]  h-[44px] rounded-2xl flex items-center gap-2 justify-center`}
              >
                <IoChevronBack />
              </button>
              <button
                onClick={handleNextStep}
                disabled={formData.lastname === ""}
                className={`text-white absolute bottom-10 right-4 sm:right-[40%] bg-[#1f1f1f] w-[290px] sm:w-[235px] px-6 py-2.5 rounded-2xl flex items-center gap-2 justify-center ${formData.lastname === "" ? "opacity-40" : "opacity-100"
                  }`}
              >
                Үргэлжлүүлэх
                <FaArrowRight />
              </button>
            </div>
          </>
        )}
        {step === 4 && (
          <>
            <div className="flex justify-center">
              <button
                onClick={handlePrevStep}
                className={`text-white absolute bottom-10 left-4 sm:left-[40%] bg-[#1f1f1f] w-[50px]  h-[44px] rounded-2xl flex items-center gap-2 justify-center`}
              >
                <IoChevronBack />
              </button>
              <button
                onClick={handleSubmit}
                disabled={formData.lastname === ""}
                className={`text-white absolute bottom-10 right-4 sm:right-[40%] bg-[#1f1f1f] w-[290px] sm:w-[235px] px-6 py-2.5 rounded-2xl flex items-center gap-2 justify-center ${formData.lastname === "" ? "opacity-40" : "opacity-100"
                  }`}
              >
                Үргэлжлүүлэх
                <FaArrowRight />
              </button>
            </div>
          </>
        )}
      </div>

      <ToastContainer />
    </div>
  );
};

export default Signup;
